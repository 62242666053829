export default {
  'notifications.OrderPaid': 'Pedido pago',
  'notifications.UserLogged': 'Usuário conectado',
  'notifications.list': 'Lista',
  'notifications.TopicFinished': 'Tópico concluído',
  'notifications.CourseAssigned': 'Curso atribuído',
  'notifications.PaymentRegistered': 'Pagamento registrado',
  'notifications.PermissionRoleRemoved': 'Função de permissão removida',
  'notifications.PermissionRoleChanged': 'Função de permissão alterada',
  'notifications.CartOrderPaid': 'Pedido no carrinho pago',
  'notifications.CourseAccessFinished': 'Acesso ao curso concluído',
  'notifications.Logout': 'Logout',
  'notifications.UserRemovedFromGroup': 'Usuário removido do grupo',
  'notifications.UserAddedToGroup': 'Usuário adicionado ao grupo',
  'notifications.CoursedPublished': 'Curso publicado',
  'notifications.ResetPassword': 'Redefinir senha',
  'notifications.CourseAccessStarted': 'Acesso ao curso iniciado',
  'notifications.CartOrderSuccess': 'Pedido concluído com sucesso',
  'notifications.AccountRegistered': 'Conta registrada',
  'notifications.ForgotPassword': 'Esqueci a senha',
  'notifications.Login': 'Login',
  'notifications.TopicTypeChanged': 'Tipo de tópico alterado',
  'notifications.AccountDeleted': 'Conta excluída',
  'notifications.AccountBlocked': 'Conta bloqueada',
  'notifications.CourseFinished': 'Curso concluído',
  'notifications.PaymentSuccess': 'Pagamento bem-sucedido',
  'notifications.PdfCreatedEvent': 'PDF criado',
  'notifications.CourseTutorUnassigned': 'Tutor do curso não atribuído',
  'notifications.CourseUnassigned': 'Curso não atribuído',
  'notifications.AccountMustBeEnableByAdmin': 'Conta deve ser habilitada pelo Admin',
  'notifications.SettingPackageConfigUpdated': 'Configuração do pacote atualizada',
  'notifications.CourseTutorAssigned': 'Tutor do curso atribuído',
  'notifications.AccountConfirmed': 'Conta confirmada',
};
