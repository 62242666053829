export default {
  'app.setting.pagestyle': 'Configuração de estilo da página',
  'app.setting.pagestyle.dark': 'Estilo escuro',
  'app.setting.pagestyle.light': 'Estilo claro',
  'app.setting.content-width': 'Largura do conteúdo',
  'app.setting.content-width.fixed': 'Fixa',
  'app.setting.content-width.fluid': 'Fluída',
  'app.setting.themecolor': 'Cor do tema',
  'app.setting.themecolor.dust': 'Vermelho poeira',
  'app.setting.themecolor.volcano': 'Vulcão',
  'app.setting.themecolor.sunset': 'Laranja pôr do sol',
  'app.setting.themecolor.cyan': 'Ciano',
  'app.setting.themecolor.green': 'Verde polar',
  'app.setting.themecolor.daybreak': 'Azul amanhecer (padrão)',
  'app.setting.themecolor.geekblue': 'Azul geek',
  'app.setting.themecolor.purple': 'Roxo dourado',
  'app.setting.navigationmode': 'Modo de navegação',
  'app.setting.sidemenu': 'Layout do menu lateral',
  'app.setting.topmenu': 'Layout do menu superior',
  'app.setting.fixedheader': 'Cabeçalho fixo',
  'app.setting.fixedsidebar': 'Barra lateral fixa',
  'app.setting.fixedsidebar.hint': 'Funciona no layout do menu lateral',
  'app.setting.hideheader': 'Ocultar cabeçalho ao rolar',
  'app.setting.hideheader.hint': 'Funciona quando o cabeçalho oculto está ativado',
  'app.setting.othersettings': 'Outras configurações',
  'app.setting.weakmode': 'Modo fraco',
  'app.setting.copy': 'Copiar configuração',
  'app.setting.copyinfo': 'Cópia bem-sucedida, por favor, substitua defaultSettings em src/models/setting.js',
  'app.setting.production.hint':
    'O painel de configurações é exibido apenas no ambiente de desenvolvimento, por favor, modifique manualmente',
};
