export default {
  'fabric.preview': 'Visualizar PDF sem valor simulado',
  'fabric.remove': 'Remover objeto',
  'fabric.text_options': 'Opções de texto',
  'fabric.image_options': 'Opções de imagem',
  'fabric.line_options': 'Opções de linha',
  'fabric.object_options': 'Opções de objeto',
  'fabric.stroke_color': 'Cor do contorno',
  'fabric.background_color': 'Cor de fundo',
  'fabric.stroke_width': 'Largura do contorno',
  'fabric.line_color': 'Cor da linha',
  'fabric.font_family': 'Família da fonte',
  'fabric.font_size': 'Tamanho da fonte',
  'fabric.text_align': 'Alinhamento do texto',
  'fabric.line_height': 'Altura da linha',
  'fabric.bold': 'Negrito',
  'fabric.italic': 'Itálico',
  'fabric.underline': 'Sublinhado',
  'fabric.linethrough': 'Riscado',
  'fabric.overline': 'Sobrelinha',
  'fabric.text_color': 'Cor do texto',
  'fabric.text_background': 'Cor de fundo do texto',
  'fabric.add_circle': 'Adicionar círculo',
  'fabric.add_rectangle': 'Adicionar retângulo',
  'fabric.add_line': 'Adicionar linha',
  'fabric.add_image': 'Adicionar imagem',
  'fabric.add_object': 'Adicionar objeto',
  'fabric.add_text': 'Adicionar texto',
  'fabric.add_textbox': 'Adicionar caixa de texto',
  'fabric.clear': 'Limpar editor',
  'fabric.editor_options': 'Opções do editor',
  'fabric.add': 'Adicionar ',
  'fabric.editor_background_color': 'Cor de fundo do editor',
};
