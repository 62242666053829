export default {
  vouchers: 'Vales',
  voucher: 'Vale',
  'voucher.value': 'Valor do vale {currency}',
  'vouchers.code': 'Código do vale',
  'vouchers.limit_usage': 'Limite de uso',
  'vouchers.max_cart_price': 'Preço máximo do carrinho',
  'vouchers.min_cart_price': 'Preço mínimo do carrinho',
  'vouchers.type': 'Tipo de vale',
  'vouchers.included_products': 'Produtos incluídos',
  'vouchers.excluded_products': 'Produtos excluídos',
  'vouchers.cart_fixed': 'Carrinho fixo',
  'vouchers.cart_percent': 'Percentual do carrinho',
  'vouchers.product_fixed': 'Produto fixo',
  'vouchers.product_percent': 'Percentual do produto',
  'voucher.edit': 'Editar vale',
  'voucher.excluded_categories': 'Categorias excluídas',
  'voucher.included_categories': 'Categorias incluídas',
  'voucher.only_for_users': 'Apenas para usuários',
};
