export default {
  'menu.welcome': 'Bem-vindo',
  'menu.more-blocks': 'Mais Blocos',
  'menu.home': 'Início',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Subpágina',
  'menu.login': 'Login',
  'menu.register': 'Registrar',
  'menu.register.result': 'Resultado do Registro',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Análise',
  'menu.dashboard.monitor': 'Monitorar',
  'menu.dashboard.workplace': 'Local de Trabalho',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formulário',
  'menu.form.basic-form': 'Formulário Básico',
  'menu.form.step-form': 'Formulário Passo a Passo',
  'menu.form.step-form.info': 'Formulário Passo a Passo (escrever informações de transferência)',
  'menu.form.step-form.confirm': 'Formulário Passo a Passo (confirmar informações de transferência)',
  'menu.form.step-form.result': 'Formulário Passo a Passo (concluído)',
  'menu.form.advanced-form': 'Formulário Avançado',
  'menu.list': 'Lista',
  'menu.list.table-list': 'Pesquisar Tabela',
  'menu.list.basic-list': 'Lista Básica',
  'menu.list.card-list': 'Lista de Cartões',
  'menu.list.search-list': 'Lista de Pesquisa',
  'menu.list.search-list.articles': 'Lista de Pesquisa (artigos)',
  'menu.list.search-list.projects': 'Lista de Pesquisa (projetos)',
  'menu.list.search-list.applications': 'Lista de Pesquisa (aplicações)',
  'menu.profile': 'Perfil',
  'menu.profile.basic': 'Perfil Básico',
  'menu.profile.advanced': 'Perfil Avançado',
  'menu.result': 'Resultado',
  'menu.result.success': 'Sucesso',
  'menu.result.fail': 'Falha',
  'menu.exception': 'Exceção',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Disparar',
  'menu.account': 'Conta',
  'menu.account.center': 'Centro de Conta',
  'menu.account.settings': 'Configurações da Conta',
  'menu.account.trigger': 'Disparar Erro',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Editor Gráfico',
  'menu.editor.flow': 'Editor de Fluxo',
  'menu.editor.mind': 'Editor de Mente',
  'menu.editor.koni': 'Editor Koni',
  'menu.reports': 'Relatórios',
};
