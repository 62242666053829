export default {
  'pages.layouts.userLayout.title':
    'O Ant Design é a especificação de design web mais influente no distrito de Xihu',
  'pages.login.accountLogin.tab': 'Login de Conta',
  'pages.login.accountLogin.errorMessage': 'Nome de usuário/senha incorretos (admin/ant.design)',
  'pages.login.username.placeholder': 'Nome de usuário: admin ou user',
  'pages.login.username.required': 'Por favor, insira seu nome de usuário!',
  'pages.login.password.placeholder': 'Senha: ant.design',
  'pages.login.password.required': 'Por favor, insira sua senha!',
  'pages.login.phoneLogin.tab': 'Login de Telefone',
  'pages.login.phoneLogin.errorMessage': 'Erro no código de verificação',
  'pages.login.phoneNumber.placeholder': 'Número de telefone',
  'pages.login.phoneNumber.required': 'Por favor, insira seu número de telefone!',
  'pages.login.phoneNumber.invalid': 'Número de telefone inválido!',
  'pages.login.captcha.placeholder': 'Código de verificação',
  'pages.login.captcha.required': 'Por favor, insira o código de verificação!',
  'pages.login.phoneLogin.getVerificationCode': 'Obter Código',
  'pages.getCaptchaSecondText': 'seg(s)',
  'pages.login.rememberMe': 'Lembrar-me',
  'pages.login.forgotPassword': 'Esqueceu a senha?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login com:',
  'pages.login.registerAccount': 'Registrar Conta',
  'pages.welcome.advancedComponent': 'Componente Avançado',
  'pages.welcome.link': 'Bem-vindo',
  'pages.welcome.advancedLayout': 'Layout Avançado',
  'pages.welcome.content': 'Olá e bem-vindo ao EscolaLMS!',
  'pages.welcome.alertMessage': 'Componentes robustos mais rápidos e fortes foram lançados.',
  'pages.admin.subPage.title': 'Esta página só pode ser vista pelo Admin',
  'pages.admin.subPage.alertMessage':
    'O Umi UI agora está lançado, bem-vindo a usar npm run ui para começar a experiência.',
  'pages.searchTable.createForm.newRule': 'Nova Regra',
  'pages.searchTable.updateForm.ruleConfig': 'Configuração da Regra',
  'pages.searchTable.updateForm.basicConfig': 'Informações Básicas',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Nome da Regra',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Por favor, insira o nome da regra!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Descrição da Regra',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Por favor, insira pelo menos cinco caracteres',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Por favor, insira uma descrição da regra com pelo menos cinco caracteres!',
  'pages.searchTable.updateForm.ruleProps.title': 'Configurar Propriedades',
  'pages.searchTable.updateForm.object': 'Objeto de Monitoramento',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Modelo de Regra',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Tipo de Regra',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Configurar Período de Agendamento',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Hora de Início',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Por favor, escolha uma hora de início!',
  'pages.searchTable.titleDesc': 'Descrição',
  'pages.searchTable.ruleName': 'Nome da regra é obrigatório',
  'pages.searchTable.titleCallNo': 'Número de Chamadas de Serviço',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'padrão',
  'pages.searchTable.nameStatus.running': 'em execução',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'anormal',
  'pages.searchTable.titleUpdatedAt': 'Último Agendamento em',
  'pages.searchTable.exception': 'Por favor, insira o motivo da exceção!',
  'pages.searchTable.titleOption': 'Opções',
  'pages.searchTable.config': 'Configuração',
  'pages.searchTable.subscribeAlert': 'Inscrever-se para alertas',
  'pages.searchTable.title': 'Formulário de Consulta',
  'pages.searchTable.new': 'Novo',
  'pages.searchTable.chosen': 'escolhido',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Número Total de Chamadas de Serviço',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'exclusão em lote',
  'pages.searchTable.batchApproval': 'aprovação em lote',
  'pages.files.content': 'Lista de Arquivos',
  'pages.files.filesBrowser': 'O diretório atual é:',
};
