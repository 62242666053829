import component from './pt-BR/component';
import globalHeader from './pt-BR/globalHeader';
import menu from './pt-BR/menu';
import pwa from './pt-BR/pwa';
import settingDrawer from './pt-BR/settingDrawer';
import settings from './pt-BR/settings';
import pages from './pt-BR/pages';
import notifications from './pt-BR/notifications';
import templates from './pt-BR/templates';
import consultations from './pt-BR/consultations';
import fabric from './pt-BR/fabric';
import vouchers from './pt-BR/vouchers';
import courses from './pt-BR/courses';
import events from './pt-BR/events';
import recommender from './pt-BR/recommender';

export default {
  actions: 'Ações',
  question: 'Pergunta',
  sort: 'Ordenar',
  newQuestion: 'Nova Pergunta',
  'Questions.delete': 'Deletar pergunta',
  'Questions.edit': 'Editar pergunta',
  'Questions.score': 'Pontuação',
  'Questions.create': 'Criar pergunta',
  'Questions.addMatchingPair': 'Adicionar par correspondente',
  'Questions.add': 'Adicionar pergunta',
  'Questions.addAnswer': 'Adicionar resposta',
  'Questions.deleteAnswer': 'Deletar resposta',
  'Questions.onlyOneAnswerIsCorrect': 'Apenas uma resposta pode ser correta',
  'Questions.answer': 'Resposta {ordinalNumber}',
  'Questions.correctAnswer': 'Resposta correta?',
  'Questions.pair': 'Par {ordinalNumber}',
  'Questions.deletePair': 'Deletar par',
  'Questions.errorMargin': 'Margem de erro',
  'Questions.answerFeedback': 'Feedback da resposta',
  weight: 'Peso',
  'menu.Payments': 'Pagamentos',
  multiple_choice: 'Escolha Múltipla',
  'menu.Vouchers': 'Vouchers',
  'menu.Orders': 'Pedidos',
  'menu.Products': 'Produtos',
  'Uploaded Projects': 'Projetos Enviados',
  'menu.Other activities.Consultation Requests': 'Solicitações de Consulta',
  'menu.Courses.Access Requests': 'Solicitações de Acesso a Cursos',
  multiple_choice_with_multiple_right_answers: 'Escolha Múltipla com Múltiplas Respostas Corretas',
  true_false: 'Verdadeiro ou Falso',
  short_answers: 'Respostas Curtas',
  matching: 'Correspondência',
  numerical_question: 'Questão Numérica',
  essay: 'Redação',
  description: 'Descrição',
  translationJsonText: 'Texto de Tradução JSON',
  translationJsonText_tooltip: 'Texto de Tradução JSON_tooltip',
  editTranslation: 'Editar Tradução',
  parent: 'Pai',
  parent_lesson: 'Aula Pai',
  courseAccessEnquiries: 'Consultas de Acesso a Cursos',
  'menu.Other activities.Tasks': 'Tarefas',
  'menu.Configuration.Panel Translations': 'Traduções do Painel de Configuração',
  lang: 'Língua',
  file_resources: 'Recursos de Arquivo',
  select_user_role: 'Selecionar Função do Usuário',
  select_user_group: 'Selecionar Grupo de Usuários',
  select_semester: 'Selecionar Semestre',
  select_attempt: 'Selecionar Tentativa',
  attempt: 'Tentativa',
  groups: 'Grupos',
  groupId: 'ID do Grupo: ',
  students: 'Estudantes',
  exams: 'Provas (Notas Parciais)',
  schedule: 'Programação',
  attendance: 'Frequência',
  'grades-scale': 'Escala de Notas',
  'final-grades': 'Notas Finais',
  'file-exports-history': 'Histórico de Exportações de Dados',
  'class-register': 'Registro de Classe',
  subject: 'Matéria',
  subjects: 'Matérias',
  groupName: 'Nome do Grupo',
  studentsList: 'Lista de Estudantes',
  nameAndSurname: 'Nome e Sobrenome',
  attendances: 'Frequências',
  noAttendanceSchedule: 'Sem Programação de Frequência',
  msTeams: 'MS Teams',
  uploadAttendance: 'Enviar Frequência',
  uploadGrades: 'Enviar Notas',
  semester: 'Semestre',
  tutorName: 'Tutor',
  examTitle: 'Título da Prova',
  examResult: 'Resultado da Prova',
  examResults: 'Resultados da Prova',
  datePassingExam: 'Data de Aprovação na Prova',
  examImportance: 'Importância da Prova (1-100%)',
  gradesType: 'Tipo de Notas a Enviar',
  uploadGradesManually: 'Enviar Notas Manualmente',
  // Long story short - client mixed types of files - so it's working like that:
  'uploadFile.MsTeamsForms': 'Enviar arquivo do MS Teams',
  'uploadFile.MsTeams': 'Enviar arquivo do TestPortal',
  'uploadFile.TestPortal': 'Enviar arquivo do MS Teams Forms',
  'navBar.lang': 'Idiomas',
  'layout.user.link.help': 'Ajuda',
  'layout.user.link.privacy': 'Privacidade',
  'layout.user.link.terms': 'Termos',
  'app.preview.down.block': 'Baixe esta página para o seu projeto local',
  'app.welcome.link.fetch-blocks': 'Obter todos os blocos',
  'app.welcome.link.block-list': 'Construa rapidamente páginas padrão baseadas no desenvolvimento de `blocos`',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...notifications,
  ...templates,
  ...consultations,
  ...fabric,
  ...vouchers,
  ...courses,
  ...events,
  ...recommender,
  branding: 'Marca',
  product_details: 'Detalhes do produto',
  title: 'Título',
  options: 'Opções',
  base_price: 'Preço base [¢]',
  base_price_tooltip: 'Preço base [¢]. Use 0 para curso gratuito',
  duration_freemode_description:
    'Por padrão, esse valor é tratado como o número de horas - para passar o valor em minutos, use este formato: "n minutos"',
  duration_freemode: 'Duração (horas)',
  duration: 'Duração',
  duration_tooltip: 'Duração, exemplo "1 hora"',
  categories: 'Categorias',
  tags: 'Tags',
  H5Ps: 'H5Ps',
  new: 'novo',
  library: 'Biblioteca',
  access: 'Acesso',
  answers: 'Respostas',
  answer: 'Resposta',
  'menu.Courses': 'Cursos',
  'menu.Courses.List': 'Lista',
  'menu.Courses.Form': 'Formulário',
  'menu.Sales': 'Vendas',
  'menu.Teacher': 'Professor',
  'menu.Teacher.Subjects': 'Matérias',
  'menu.Users': 'Usuários',
  'menu.Users.List': 'Lista',
  'menu.Users.User': 'Usuário',
  'menu.Users.User Form': 'Usuários',
  'menu.Welcome': 'Bem-vindo',
  'menu.Courses.Course Form': 'Formulário de Curso',
  'menu.Users.Form': 'Formulário',
  'menu.Courses.H5Ps': 'H5Ps',
  'menu.Courses.Categories': 'Categorias',
  'menu.Configuration': 'Configuração',
  'menu.Configuration.Files': 'Arquivos',
  'menu.Configuration.Translations': 'Traduções',
  'menu.Other activities.Pages': 'Páginas',
  'menu.Sales.Payments': 'Pagamentos',
  'menu.Sales.Orders': 'Pedidos',
  'menu.Sales.Products': 'Produtos',
  'menu.Courses.SCORMs': 'SCORMs',
  'menu.Configuration.settings': 'Configurações',
  'menu.Analytics.reports': 'Relatórios',
  'menu.Courses.Webinars': 'Webinars',
  'menu.Courses.Quiz Reports': 'Relatórios de Quiz',
  'menu.Courses.Details': 'Detalhes',
  'menu.Users.User Groups': 'Grupos de Usuários',
  'menu.Other activities.Questionnaire': 'Questionários',
  'menu.Other activities.Questionnaire Form': 'Formulário de Questionário',
  'menu.Other activities': 'Outras atividades',
  'menu.Other activities.Consultations': 'Consultas',
  'menu.Other activities.StationaryEvents': 'Eventos Estacionários',
  'menu.Analytics.Logs': 'Logs',
  'menu.Sales.Vouchers': 'Vouchers',
  'menu.Competency challenges': 'Desafios de Competência',
  'menu.Competency challenges.List': 'Lista',
  'menu.Competency challenges.Competency challenge': 'Desafio de Competência',
  'stationary_event.edit': 'Formulário de edição de evento estacionário',
  stationary_event: 'Evento estacionário',
  finished_at: 'Data de término',
  finished_at_tooltip: 'Data de término',
  started_at: 'Data de início',
  started_at_tooltip: 'Data de início',
  end_at: 'Data de término',
  max_participants: 'Número máximo de participantes',
  max_participants_tooltip: 'Número máximo de participantes',
  StationaryEvents: 'Eventos estacionários',
  'menu.reset': 'resetar',
  'menu.settings': 'Configurações',
  'menu.Settings': 'Configurações',
  settings: 'configurações',
  package: 'pacote',
  id: 'id',
  place: 'Local',
  place_tooltip: 'Local',
  user_groups: 'Grupos de Usuários',
  new_user_group: 'Novo Grupo de Usuários',
  edit_user_group: 'Editar Grupo de Usuários',
  add_person_to_group: 'Adicionar pessoa ao grupo',
  user_settings: 'Configurações do usuário',
  global_settings: 'Configurações globais',
  'user.settings': 'Configurações do usuário',
  'user.settings.overwrite': 'Sobrescrever tudo',
  Active: 'Ativo',
  address: 'Endereço',
  Inactive: 'Inativo',
  active: 'Ativo',
  inactive: 'Inativo',
  draft: 'Rascunho',
  published: 'Publicado',
  archived: 'Arquivado',
  slug: 'Slug',
  name: 'Nome',
  ID: 'ID',
  newCategory: 'Criar nova Categoria',
  editCategory: 'Editar Categoria',
  parent_category: 'Categoria pai',
  is_active: 'Ativo?',
  is_highlighted: 'Destacado?',
  parent_id: 'Categoria pai',
  parent_id_tooltip: 'A categoria pai à qual a nova categoria será atribuída',
  parent_id_group: 'Grupo pai',
  parent_id_group_tooltip:
    'O resultado da pesquisa retornará todas as categorias vinculadas à categoria pai',
  registerable: 'Registrável',
  edit: 'Editar',
  delete: 'Excluir',
  deleteQuestion: 'Tem certeza de que deseja excluir este registro?',
  payments: 'Pagamentos',
  order: 'Pedido',
  orders: 'Pedidos',
  amount: 'Quantidade',
  status: 'Status',
  users: 'Usuários',
  user: 'Usuário',
  first_name: 'Nome',
  last_name: 'Sobrenome',
  email: 'Email',
  email_tooltip: 'Email',
  email_resend: 'Email reenviado',
  email_field: 'Por favor, insira seu email',
  event: 'Evento',
  general: 'Geral',
  password: 'Senha',
  current_password: 'Senha atual',
  new_password: 'Nova senha',
  new_confirm_password: 'Confirmar nova senha',
  notifications: 'Notificações',
  change_password: 'Alterar senha',
  password_field: 'Por favor, insira sua senha',
  search: 'Pesquisar',
  search_tooltip_1: 'Pesquisará por nome, sobrenome e email',
  search_tooltip_user_groups: 'Pesquisará por nome',
  roles: 'Funções',
  rodo: 'Política de Privacidade',
  regulations: 'Termos de Serviço',
  permissions: 'Permissões',
  verified: 'Verificado',
  not_verified: 'Não verificado',
  is_email_verified: 'Email verificado',
  email_verified: 'Email verificado',
  dateRange: 'Intervalo de datas',
  created_at: 'Criado em',
  create_new: 'Criar novo',
  updated_at: 'Atualizado em',
  unsaved_changes: 'As alterações que você fez podem não estar salvas.',
  currency: 'Moeda',
  subtotal: 'Subtotal',
  tax: 'Imposto',
  total: 'Total',
  items: 'Itens',
  author: 'Autor',
  course: 'Curso',
  select: 'Por favor, selecione',
  select_tags: 'Selecionar tags',
  to: 'para',
  select_person: 'Selecionar uma pessoa',
  select_course: 'Selecionar um curso',
  select_groups: 'Selecionar grupos',
  select_group: 'Selecionar um grupo',
  select_final_grade: 'Selecionar uma nota final',
  select_all: 'Selecionar todos',
  deselect_all: 'Desmarcar todos',
  select_templates: 'Selecionar um modelo',
  select_type_topic: 'Selecionar tipo de tópico para continuar',
  select_scorm_package: 'Selecionar pacote SCORM',
  select_certificate_package: 'Selecionar modelo de certificado',
  billable: 'Faturável para',
  payable: 'Pagável para',
  newH5P: 'Novo arquivo H5P',
  H5P_scorm_preview: 'H5P. Visualização de conteúdo Scorm',
  H5P_uploaded: 'Novo arquivo H5P enviado com sucesso',
  H5P_preview_title: 'H5P. Visualização de Conteúdo HTML',
  H5P_new_content_title: 'H5p. Novo',
  H5P_edit_content_title: 'H5p. Editar',
  H5P_select_content: 'Selecionar conteúdo para H5P',
  upload_click_here: 'Clique aqui para adicionar arquivo',
  upload: 'Adicionar arquivo',
  preview: 'Visualizar',
  export: 'Exportar',
  able_to_preview: 'Capaz de visualizar',
  loading_content: 'Carregando conteúdo...',
  preview_content: 'Visualizar conteúdo',
  version: 'Versão',
  SCOS: 'Objetos de Conteúdo Compartilhável',
  assign: 'Atribuir',
  newScorm: 'Novo SCORM',
  scorm_uploaded: 'Novo SCORM enviado',
  yes: 'Sim',
  no: 'Não',
  cantDelete: "Você não pode excluir esta categoria porque é pai de outras",
  group: 'Grupo',
  key: 'Chave',
  type: 'Tipo',
  enumerable: 'Enumerável',
  note: 'Nota',
  public: 'Público',
  value: 'Valor',
  editSetting: 'Editar',
  newSetting: 'Criar',
  success: 'Sucesso',
  error: 'Erro',
  loading: 'Carregando',
  subtitle: 'Legenda',
  hour: 'Hora',
  language: 'Idioma',
  level: 'Nível',
  active_from: 'Ativo de',
  active_to: 'Ativo até',
  hours_to_complete: 'Horas para completar',
  purchasable: 'Comprável',
  findable: 'Encontrável',
  target_group: 'Grupo-alvo',
  author_tutor: 'Autor / Tutor',
  summary: 'Resumo',
  summary_tooltip:
    'O editor é WYSIWYG e inclui ferramentas de formatação, mantendo a capacidade de escrever atalhos de markdown inline e produzir markdown simples.',
  short_description: 'Descrição curta',
  description_tooltip:
    'O editor é WYSIWYG e inclui ferramentas de formatação, mantendo a capacidade de escrever atalhos de markdown inline e produzir markdown simples.',
  attributes: 'Atributos',
  new_course: 'Novo curso',
  new_questionnaire: 'Novo questionário',
  questionnaires: 'Questionários',
  questionnaire: 'Questionário',
  'questionnaire.submit': 'Enviar',
  'questionnaire.raports.filter': 'Filtrar por modelos atribuídos',
  questions: 'Perguntas',
  question_list: 'Lista de perguntas',
  question_add: 'Adicionar pergunta',
  question_edit: 'Editar pergunta',
  media: 'Mídia',
  image: 'Imagem',
  poster: 'Pôster',
  video: 'Vídeo',
  audio: 'Áudio',
  categories_tags: 'Categorias e tags',
  program: 'Programa',
  program_tooltip:
    'O editor é WYSIWYG e inclui ferramentas de formatação, mantendo a capacidade de escrever atalhos de markdown inline e produzir markdown simples',
  position: 'Posição',
  scorm: 'SCORM',
  add_new_lesson: 'Adicionar nova lição',
  add_lesson: 'Adicionar lição',
  lesson: 'Lição',
  topic: 'Tópico',
  topics: 'Tópicos',
  topic_list: 'Lista de tópicos',
  topic_types: 'Tipos de tópicos',
  tutor: 'Tutor',
  tutors: 'Tutores',
  add_new_topic: 'Adicionar novo tópico',
  no_topics: 'Ainda não há tópicos nesta lição',
  no_data: 'Sem dados',
  create: 'Criar',
  save: 'Salvar',
  relase_drop: 'Liberar para soltar',
  drag_here: 'Arraste uma caixa aqui',
  sort_element_up: 'Ordenar este elemento para cima',
  sort_element_down: 'Ordenar este elemento para baixo',
  copy_lesson: 'Copiar lição',
  copy_topic: 'Copiar tópico',
  'h5p.description.header': 'Informações do H5P',
  'h5p.description.first': 'Para atribuir um elemento HTML5 interativo ao tópico da lição, você precisa ',
  'h5p.description.second': 'criá-lo primeiro',
  'h5p.description.third': ' ou usar um ',
  'h5p.description.fourth': 'da lista',
  'h5p.description.fifth': ' se já estiver criado. Pressione',
  'h5p.description.sixth':
    'botão para ver como o conteúdo se parece e ver a lista de eventos `XAPI`.',
  my_profile: 'Meu perfil',
  logout: 'Sair',
  bio: 'Biografia',
  bio_tooltip:
    'O editor é WYSIWYG e inclui ferramentas de formatação, mantendo a capacidade de escrever atalhos de markdown inline e produzir markdown simples.',
  avatar: 'avatar',
  avatar_placeholder: 'Adicionar avatar para ver a prévia',
  wysiwyg_placeholder:
    "Digite aqui o texto para ver as funcionalidades do editor de texto rico. Comece com '/' para abrir opções de formatação ou clique no botão '+'",
  edit_user: 'Editar usuário',
  new_user: 'Novo usuário',
  send: 'Enviar',
  resend: 'Reenviar',
  back: 'Voltar',
  back_home: 'Voltar para a página principal',
  '403_subtitle': 'Desculpe, você não tem autorização para acessar esta página.',
  '404_subtitle': 'Desculpe, a página que você visitou não existe.',
  user_id: 'ID do usuário: ',
  order_id: 'ID do pedido: ',
  course_id: 'ID do curso: ',
  consultation_id: 'ID da consulta: ',
  webinar_id: 'ID do webinar: ',
  stationary_event_id: 'ID do evento estacionário: ',
  gift_quiz_id: 'ID do Quiz de Presente: ',
  count_h5p: 'Contagem de H5P',
  library_id: 'ID da biblioteca',
  new_payment: 'Novo',
  paid: 'Pago',
  cancelled: 'Cancelado',
  processing: 'Processando',
  cancel: 'Cancelar',
  ok: 'OK',
  failed: 'falhou',
  clear_selection: 'limpar seleção',
  form: 'Formulário',
  none: 'Nenhum',
  too_many_chars: 'Muitos caracteres',
  CoursesMoneySpentMetric: 'Métrica de dinheiro gasto em cursos',
  CoursesPopularityMetric: 'Métrica de popularidade de cursos',
  CoursesSecondsSpentMetric: 'Métrica de tempo gasto em cursos',
  TutorsPopularityMetric: 'Métrica de popularidade de tutores',
  AverageTimePerTopic: 'Tempo médio por tópico',
  MoneyEarned: 'Dinheiro ganho',
  CertificateTemplates: 'Modelos de certificados',
  AverageTime: 'Tempo total',
  People: 'Pessoas',
  PeopleBought: 'Pessoas que compraram este curso',
  PeopleFinished: 'Pessoas que concluíram este curso',
  PeopleStarted: 'Pessoas que começaram este curso',
  reuse_existing: 'Reutilizar existente',
  open_new_content_editor: 'Abrir novo editor de conteúdo',
  content: 'Conteúdo',
  contact: 'Contato',
  country: 'País',
  slug_tooltip: 'SLUG faz parte da URL das páginas ',
  sum_rate: 'Classificação',
  show_hide_on_front: 'Mostrar/Ocultar na frente',
  visible: 'Visível',
  hidden: 'Oculto',
  question_answer_visibility_changed: 'Visibilidade da resposta alterada',
  count_answers: 'Contagem de respostas',
  content_tooltip:
    'O editor é WYSIWYG e inclui ferramentas de formatação, mantendo a capacidade de escrever atalhos de markdown inline e produzir markdown simples.',
  pages: 'Páginas',
  page: 'Página',
  import_users: 'Importar usuários',
  import_file: 'Importar arquivo',
  new_page: 'Nova página estática',
  edit_page: 'Editar página estática',
  example_json: 'Exemplo JSON',
  can_skip: 'Pode ser omitido',
  statistics: 'Estatísticas',
  certificates: 'Certificados',
  query: 'Consulta',
  reset: 'Redefinir',
  reset_password: 'Redefinir senha',
  search_file: 'Procurar arquivo',
  open_editor: 'Abrir editor',
  json: 'Metadados JSON',
  additional: 'Adicional',
  json_tooltip: 'Metadados adicionais para renderizar o curso',
  introduction: 'Introdução',
  introduction_tooltip:
    'O editor é WYSIWYG e inclui ferramentas de formatação, mantendo a capacidade de escrever atalhos de markdown inline e produzir markdown simples.',
  Push: 'Enviar',
  templates: 'Modelos',
  SMS: 'SMS',
  PDF: 'PDF',
  'menu.Analytics': 'Análise',
  'menu.Analytics.Notifications': 'Notificações',
  'menu.Users.Roles': 'Funções',
  'menu.Configuration.Templates': 'Modelos',
  'menu.Users.Permissions': 'Permissões',
  new_template: 'Novo modelo',
  template: 'Modelo',
  contentHtml: 'Conteúdo HTML',
  contentMjml: 'Conteúdo Mjml',
  html_tooltip: 'Conteúdo HTML',
  product: 'Produto',
  'product.edit': 'Editar Produto',
  'menu.Other activities.Products': 'Produtos',
  'menu.Other activities.Form': 'Formulário',
  H5P: 'H5P',
  'webinar.edit': 'Editar Webinar',
  role: 'Função',
  'course.validate_edit.header':
    'Este curso já está em andamento! Tem certeza de que deseja editá-lo?',
  'course.validate_edit.content':
    'Você não deve editar este curso, pois ele já está em andamento. Clicar em OK permitirá que você edite, mas você o faz por sua própria conta e risco.',
  Course: 'Curso',
  Consultation: 'Consulta',
  limit_total: 'Limite total',
  limit_total_tooltip: 'Número máximo de produtos que podem ser comprados',
  limit_per_user: 'Limite por usuário',
  limit_per_user_tooltip:
    'Limite de compra por pessoa. Por exemplo, um valor de 1 permitirá que o usuário compre um produto',
  extra_fees: 'Taxas adicionais {currency}',
  extra_fees_tooltip: 'Taxas adicionais que serão cobradas ao comprar o produto',
  price_old: 'Preço antigo {currency}',
  price_old_tooltip: 'Preço antigo que será exibido riscado ao lado do preço atual',
  tax_value: 'Valor do imposto {currency}',
  tax_value_tooltip: 'Valor do imposto, calculado automaticamente',
  price_brutto: 'Preço bruto {currency}',
  price_brutto_tooltip: 'Preço bruto do produto, calculado automaticamente',
  tax_rate: 'Taxa de imposto',
  tax_rate_tooltip: 'Taxa de imposto especificada como porcentagem',
  price: 'Preço {currency}',
  price_in_cents: 'Preço (em centavos)',
  price_tooltip: 'Preço do produto',
  teaser_url: 'URL do teaser',
  teaser_url_tooltip: 'URL para teaser',
  select_product: 'Selecionar produto',
  productables: 'Objeto atribuído ao produto',
  productables_tooltip: 'Objeto atribuído ao produto',
  bundle: 'Pacote',
  single: 'Individual',
  StationaryEvent: 'Evento estacionário',
  default: 'Valor padrão',
  text: 'texto',
  varchar: 'varchar',
  number: 'número',
  boolean: 'booleano',
  editModelField: 'Editar campo do modelo',
  ModelFields: 'Campos do modelo',
  list: 'Lista',
  newModelField: 'Novo campo do modelo',
  notPowerOfTwo: 'Isso não é uma potência de 2',
  rules: 'Regras',
  visibility: 'Visibilidade',
  name_tooltip: 'Nome',
  type_tooltip:
    'Especifique o tipo de campo: campo de escolha única, campo numérico, campo varchar, campo de texto ou formato JSON',
  rules_tooltip: 'uma lista de regras de string JSON usadas na criação/atualização do modelo',
  extra_tooltip:
    'Valores JSON adicionais. Por exemplo, se você quiser criar traduções de campo, é necessário inserir valores no editor: [{"pl": "Tradução em polonês"},{"en": "Tradução em inglês"}]',
  default_tooltip:
    'O valor padrão para o campo selecionado, que será exibido se o valor ainda não tiver sido salvo no banco de dados',
  visibility_tooltip:
    'Visibilidade do campo para a API. Valor 1 - visível publicamente, 2 - visível para usuários logados, 4 - visível para o administrador.',
  'available-validation-rules': 'métodos de validação disponíveis',
  'available-validation-rules-tooltip':
    'Validação do campo selecionado. Por exemplo, se você quiser que um campo seja obrigatório, você deve fornecer um valor no editor JSON: ["required"]',
  Products: 'Produtos',
  free: 'grátis',
  free_capi: 'Grátis',
  prices: 'Preços',
  additional_fields: 'Campos adicionais',
  user_to_add: 'Anexar usuário ao produto',
  user_submission: 'Usuários anexados sem conta',
  email_to_add: 'Adicionar usuário por endereço de e-mail',
  Webinar: 'Webinar',
  productable: 'Entidade',
  all: 'Todos',
  categories_and_tags: 'Categorias e Tags',
  course_edit_warning_message:
    'Este registro já está em andamento, se desejar editar, clique em confirmar. Você está editando por sua conta e risco',
  user_logs: 'Logs do usuário',
  http_method: 'Método HTTP',
  path: 'Caminho',
  generated_pdfs: 'PDFs gerados',
  download_all: 'Baixar tudo',
  download_pdf: 'Baixar PDF',
  select_content: 'Selecionar conteúdo',
  exclude_promotions: 'Pode ser combinado com outras promoções',
  generate: 'Gerar',
  generate_pdf: 'Gerar PDF(s)',
  generate_pdf_tooltip: 'Gerar PDF(s)',
  generate_pdf_users_tooltip:
    'Clique neste botão para gerar PDF para este modelo para todos os usuários da lista',
  generate_pdf_products_tooltip:
    'Clique neste botão para gerar PDF para este modelo para todos os usuários que compraram o produto selecionado',
  generate_pdf_for_products_tooltip:
    'Clique neste botão para gerar PDF para o modelo selecionado para todos os usuários que compraram este produto',
  select_template: 'Selecionar modelo',
  product_categories_and_tags: 'Categorias e Tags de Produtos',
  cart_media: 'Mídia do Carrinho',
  agenda: 'Agenda',
  users_attached: 'Usuários Anexados',
  generate_token: 'Gerar token do YouTube',
  browse: 'Navegar pelos arquivos',
  related_products: 'Produtos Relacionados',
  selected_products: 'Objetos selecionados',
  quantity: 'Quantidade',
  gt_last_login_day: '>= Último login nos últimos n dias',
  lt_last_login_day: '<= Último login nos últimos n dias',
  json_editor: 'Abrir editor JSON',
  created_before: 'Criado antes',
  created_after: 'Criado depois',
  redirect: 'redirecionar',
  logotype: 'Logotipo',
  published_unactivated: 'Publicado não ativado',
  active_from_extra: 'Selecione uma data de ativação de vendas',
  active_to_extra: 'Selecione uma data de desativação de vendas',
  duration_extra: 'Especifique a duração em minutos',
  course_success_modal_title: 'Parabéns!',
  course_success_modal_content:
    'Você criou um curso. Agora você pode criar lições para o seu curso.',
  course_success_modal_button_ok: 'Criar módulos',
  course_success_modal_button_cancel: 'Ir para a página do curso',
  program_form_empty_list_message:
    'Você ainda não tem conteúdo para visualizar. Crie sua primeira lição para começar.',
  program_form_not_selected_list_message: 'Selecione uma lição ou tópico para editar.',
  clone: 'Clonar',
  drag_topic_tooltip: 'Clique para editar ou arraste e solte para reordenar o tópico.',
  invalidDate: 'Data inválida',
  badPassword: 'mín. 8 caracteres, 1 letra maiúscula, 1 caractere especial (!@#$%^&*))',
  product_widget_title: 'Atributos do Produto',
  product_widget_tooltip: 'Todos os atributos abaixo estão relacionados apenas ao produto',
  course_guide_title: 'Não tem certeza de como criar um curso?',
  course_guide_text: 'Confira nosso guia e aprenda como fazer isso.',
  global_settings_alert_title: 'Adicionar configurações globais',
  global_settings_alert_description:
    'As configurações globais são usadas para compartilhar dados em todo o LMS. Você pode adicionar configurações globais clicando no botão abaixo.',
  go_to_platform: 'Ir para a sua plataforma',
  manage_course: 'Gerenciar curso',
  tutorial: 'Tutorial',
  tutorial_card_left_title: 'Você está pronto para compartilhar seu conhecimento.',
  tutorial_card_left_description:
    'Use as melhores práticas e recomendações para planejar, produzir e publicar um curso substancial e de alta qualidade.',
  tutorial_card_right_title: 'Não tem certeza de como criar um curso?',
  tutorial_card_right_description: 'Confira nosso guia e aprenda como fazer isso.',
  learn_more: 'Saiba mais',
  your_courses: 'Seus cursos',
  ratings: 'Avaliações',
  ratings_description: 'Meça a avaliação dada aos seus cursos',
  unverified: 'Não verificado',
  download: 'Baixar',
  newTranslation: 'Nova Tradução',
  documentation: 'Documentação',
  hall_of_fame: 'Hall da Fama',
  current_user_status: 'Status atual do usuário',
  public_course: 'Público',
  no_pricing: 'Sem Preço/Acesso',
  public_label: 'É público (disponível gratuitamente)',
  public_tooltip: 'O curso é acessível para qualquer pessoa sem precisar de uma conta',
  changesToBeApproved: 'Alterações a serem aprovadas',
  orderHasBeenChanged: 'O pedido foi alterado',
  or: 'ou',
  no_base_url: "O valor de 'return_url' não está definido. Vá para: ",
  max_attempts: 'Tentativas máximas',
  max_execution_time: 'Tempo máximo de execução',
  max_execution_time_tooltip: 'A duração máxima do exame em minutos.',
  min_pass_score: 'Pontuação mínima para passar',
  min_pass_score_tooltip: 'A pontuação mínima que um aluno deve alcançar para passar (em %).',
  status_consultation_tooltip: 'Status',
  tutor_consultation_tooltip: 'Tutor',
  proposed_terms_tooltip: 'Termos propostos',
  quiz_reports: 'Relatórios de Quiz',
  details: 'Detalhes',
  uncompleted: 'Incompleto',
  student: 'Estudante',
  result_score: 'Pontuação do resultado',
  max_score: 'Pontuação máxima',
  gift_quiz: 'GIFT Quiz',
  quiz_report_details: 'Detalhes do relatório do quiz',
  'gift_type.multiple_choice': 'Escolha Múltipla',
  'gift_type.multiple_choice_with_multiple_right_answers':
    'Escolha Múltipla com Múltiplas Respostas Certas',
  'gift_type.true_false': 'Verdadeiro e Falso',
  'gift_type.matching': 'Correspondência',
  'gift_type.numerical_question': 'Questão Numérica',
  'gift_type.essay': 'Redação',
  'gift_type.description': 'Descrição',
  'gift_type.short_answers': 'Respostas Curtas',
  possible_score: 'Pontuação possível',
  possible_answers: 'Respostas possíveis',
  student_score: 'Pontuação do aluno',
  student_answer: 'Resposta do aluno',
  true_answer: 'Verdadeiro',
  false_answer: 'Falso',
  possible_true_false: 'Verdadeiro, Falso',
  matching_questions: 'Perguntas de Correspondência',
  matching_answers: 'Respostas de Correspondência',
  student_didnt_answer: 'O aluno não respondeu a esta pergunta',
  'User Not Found': 'Usuário não encontrado',
  notify_users: 'Usuários a serem notificados',
  notify_users_tooltip: 'Indique quais usuários devem ser notificados sobre o upload do projeto',
  root: 'Raiz',
  'Product Not Found': 'Produto não encontrado',
  'Consultation Not Found': 'Consulta não encontrada',
  'Order Not Found': 'Pedido não encontrado',
  field_required: 'Campo obrigatório',
  number_between: 'O campo deve ter um valor entre {min} e {max}',
  must_be_a_number: 'O valor do campo deve ser um número',
  add_title_here: 'Adicionar título aqui',
  user_projects: 'Projetos do usuário',
  project_solutions: 'Soluções do projeto do usuário',
  'Course cloning started. This may take a while.': 'Início da clonagem do curso. Isso pode levar um tempo',
  'Course deleted successfully': 'Curso excluído com sucesso',
  'Course imported successfully': 'Curso importado com sucesso',
  'Course updated successfully': 'Curso atualizado com sucesso',
  'Export created': 'Arquivo exportado com sucesso',
  'QuestionType.rate': 'Pergunta',
  'QuestionType.text': 'Texto',
  'QuestionType.review': 'Revisão (classificação + comentário)',
  'model.boolean': 'Campo de escolha única',
  'model.number': 'Campo numérico',
  'model.varchar': 'Campo de texto (varchar)',
  'model.text': 'Campo de texto',
  'model.json': 'JSON',
  'course.finished': 'Término do curso',
  'course.attempt_number': 'Número de tentativas',
  'course.another_attempts': 'Outras tentativas',
  'course.interactions_time_in_days': 'Tempo gasto em interações em dias específicos',
  'TeacherSubjects.Exams.exam_date': 'Data do exame',
  'TeacherSubjects.Exams.grade_weight': 'Peso',
  'SemesterType.winter': 'Inverno {year}',
  'SemesterType.summer': 'Verão {year}',
  'ExamGradeType.manual': 'Manual',
  'ExamGradeType.teams_forms': 'MS Teams',
  'ExamGradeType.teams_lecture': 'Portal de Testes',
  'ExamGradeType.test_portal': 'MS Teams Forms',
  'TeacherSubjects.Exams.results': 'Resultados',
  'TeacherSubjects.Exams.manualConvert': 'Selecione os grupos aos quais deseja atribuir notas',
  // Long story short - client mixed types of files - so it's working like that:
  'TeacherSubjects.Exams.teams_formsConvert': 'Enviar arquivo MS Teams',
  'TeacherSubjects.Exams.teams_lectureConvert': 'Enviar arquivo do Portal de Testes',
  'TeacherSubjects.Exams.test_portalConvert': 'Enviar arquivo MS Teams Forms',
  degree: 'Grau',
  grade: 'Nota',
  max_percent: 'Porcentagem máxima (%)',
  min_percent: 'Porcentagem mínima (%)',
  min_points: 'Pontos mínimos',
  'TeacherSubjects.FinalGrades.Student': 'Estudante: {first_name} {last_name}',
  'TeacherSubjects.FinalGrades.StudentPartialGrades': `Notas parciais`,
  'TeacherSubjects.FinalGrades.Attendance': 'Frequência',
  'TeacherSubjects.FinalGrades.Attendances': 'Frequências',
  'TeacherSubjects.FinalGrades.FinalGrades': 'Notas finais',
  'TeacherSubjects.FinalGrades.GradesScale': 'Escala de notas para a disciplina',
  'TeacherSubjects.FinalGrades.CoursesProgress': 'Progresso nos cursos',
  'TeacherSubjects.FinalGrades.GradeTerm': 'Data de vencimento',
  'TeacherSubjects.FinalGrades.FinalGrade': 'Nota final',
  'TeacherSubjects.FinalGrades.ProposedGrade': 'Nota proposta: {grade}',
  'TeacherSubjects.FinalGrades.IssueAssessment': 'Emitir uma avaliação',
  date: 'Data',
  issued_at: 'Emitido em',
  icon: 'Ícone',
  approved: 'Aprovado',
  pending: 'Pendente',
  extra_data: 'Dados extras',
  show: 'Mostrar',
  phone_number: 'Número de telefone',
  contact_details: 'Detalhes de contato',
  assigned: 'Atribuído',
  completed_at: 'Concluído em',
  Finished: 'Concluído:',
  Started: 'Iniciado:',
  NotStarted: 'Não iniciado:',
  ShowMinutes: 'Mostrar minutos gastos no tópico:',
  sent: 'Enviado',
  accepted: 'Aceito',
  rejected: 'Rejeitado',
  'confirmModal.title': 'Confirmar alterações',
  'confirmModal.content': 'Se você deseja ir para a próxima guia, salve as alterações atuais',
  read_all: 'Ler tudo',
  new_lessons_cant_be_moved: "Nova lição não pode ser movida",
  topics_cant_be_nested: "Tópicos não podem ser aninhados",
  topic_cant_be_in_root: 'O tópico deve ser filho de uma lição',
  lessons_and_topics_cant_be_mixed: "Lições e tópicos não podem ser alternados",
  max_lesson_nesting: 'O nível máximo de aninhamento de lições é {max}',
  min_topic_nesting: 'O nível mínimo de aninhamento de tópicos é {min}',
  stacking_order: 'Ordem',
  stacking_order_tooltip: 'Ordem da lição no contexto da lição ou do curso',
  exportData: 'Exportar dados',
  created_by: 'Criado por',
  exported_at: 'Exportado em',
  exported_by: 'Exportado por',
  examTitleWithWeight: '{title} (peso: {weight}%)',
  classRegisterTitleWithGroupName: 'Registro de classe do grupo {groupName}',
  gradesScalesMissing: 'Não há escala de notas para o professor selecionado',
  proposed_grade: 'Nota proposta',
  groupDataMissing: 'Dados ausentes para o grupo {group_name}',
  file_downloaded: 'Arquivo baixado',
  file_downloaded_error: 'Não foi possível baixar o arquivo',
  present: 'Presente',
  not_exercising: 'Não praticando',
  excused_absence: 'Falta justificada',
  pressSaveToCreateQuestions: 'Crie este Tópico antes de adicionar perguntas',
  addNewQuestion: 'Adicionar nova pergunta',
  selectNewQuestionType: 'Selecionar novo tipo de pergunta',
  add_new_component: 'Adicionar novo componente',
  category: 'Categoria',
  categoryId: 'ID da categoria: ',
  'CompetencyChallenges.main': 'Dados',
  'CompetencyChallenges.scales': 'Escalas',
  'CompetencyChallenges.diagnostic-test': 'Teste diagnóstico',
  'CompetencyChallenges.base': 'Base',
  'CompetencyChallenges.additional': 'Adicional',
  'CompetencyChallenges.new_challenge': 'Novo desafio',
  'Competency challenge updated successfully.': 'Desafio de competência atualizado com sucesso',
  'Competency challenge created successfully.': 'Desafio de competência criado com sucesso',
  'CompetencyChallenges.addNewRow': 'Adicionar nova linha',
};
