import component from './pl-PL/component';
import globalHeader from './pl-PL/globalHeader';
import menu from './pl-PL/menu';
import pwa from './pl-PL/pwa';
import settingDrawer from './pl-PL/settingDrawer';
import settings from './pl-PL/settings';
import pages from './pl-PL/pages';
import notifications from './pl-PL/notifications';
import templates from './pl-PL/templates';
import consultations from './pl-PL/consultations';
import fabric from './pl-PL/fabric';
import vouchers from './pl-PL/vouchers';
import courses from './pl-PL/courses';
import events from './pl-PL/events';
import recommender from './pl-PL/recommender';

export default {
  courseAccessEnquiries: 'Zapytania od dostęp do kursu',
  parent: 'Nadrzedny',
  parent_lesson: 'Nadrzedna Lekcja',
  'menu.Other activities.Tasks': 'Zadania',
  tableToolBar: {
    densitySmall: 'aaaa',
  },
  'tableToolBar.densitySmall': 'aaaa',
  file_resources: 'Załączniki (pliki)',
  select_user_role: 'Wybierz rolę',
  select_user_group: 'Wybierz grupę',
  select_semester: 'Wybierz semestr',
  select_attempt: 'Wybierz podejście',
  attempt: 'Podejście',
  groups: 'Grupy',
  groupId: 'ID Grupy: ',
  students: 'Studenci',
  exams: 'Egzaminy (oceny cząstkowe)',
  schedule: 'Harmonogram',
  attendance: 'Obecności',
  'grades-scale': 'Skala ocen',
  'final-grades': 'Oceny końcowe',
  'file-exports-history': 'Historia eksportowania danych',
  'class-register': 'Dziennik',
  msTeams: 'MS Teams',
  uploadAttendance: 'Wgraj obecność',
  uploadGrades: 'Wgraj oceny',
  semester: 'Semestr',
  subject: 'Przedmiot',
  subjects: 'Przedmioty',
  groupName: 'Nazwa grupy',
  studentsList: 'Lista studentów',
  nameAndSurname: 'Imię i nazwisko',
  attendances: 'Obecności',
  noAttendanceSchedule: 'Brak harmonogramu obecności dla tej grupy...',
  tutorName: 'Prowadzący',
  examTitle: 'Wpisz tytuł egzaminu',
  examResult: 'Wynik egzaminu',
  examResults: 'Wyniki egzaminu',
  datePassingExam: 'Data zaliczenia egzaminu',
  examImportance: 'Waga egzaminu (1-100%)',
  gradesType: 'Typ wgrywania ocen',
  uploadGradesManually: 'Wgraj oceny ręcznie',
  // Long story short - client mixed types of files - so it's working like that:
  'uploadFile.MsTeamsForms': 'Wgraj plik z MS Teams',
  'uploadFile.MsTeams': 'Wgraj plik z TestPortal',
  'uploadFile.TestPortal': 'Wgraj plik z  MS Teams Forms',
  'navBar.lang': 'Języki',
  'layout.user.link.help': 'Pomoc',
  'layout.user.link.privacy': 'Prywatność',
  'layout.user.link.terms': 'Warunki',
  'app.preview.down.block': 'Pobierz tę stronę do swojego lokalnego projektu',
  'app.welcome.link.fetch-blocks': 'Weź wszystkie bloki',
  'app.welcome.link.block-list': 'Szybko buduj standardowe strony w oparciu o `block` development ',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...notifications,
  ...templates,
  ...consultations,
  ...fabric,
  ...vouchers,
  ...courses,
  ...events,
  ...recommender,
  branding: 'Branding',
  product_details: 'Ustawienia produktu',
  title: 'Tytuł',
  options: 'Opcje',
  base_price: 'Cena podstawowa [gr]',
  base_price_tooltip: 'Cena podstawowa [gr]. Wpisz 0 dla darmowego kursu',
  duration_freemode_description:
    'Wartość ta traktowana jest jako ilość godzin - Aby podać wartość w minutach należy użyć formatu "n minutes"',
  duration_freemode: 'Czas trwania (godz)',
  duration: 'Czas trwania',
  duration_tooltip: 'Czas trwania, przykładowo "1 godzina"',
  categories: 'Kategorie',
  tags: 'Tagi',
  H5P: 'H5P',
  H5Ps: 'H5P',
  new: 'Nowy',
  library: 'Biblioteka',
  'menu.Courses': 'Kursy',
  'menu.Teacher': 'Dydaktyk',
  'menu.Teacher.Subjects': 'Przedmioty',
  'menu.Users': 'Użytkownicy',
  'menu.Users.List': 'Lista',
  'menu.Courses.List': 'Lista',
  'menu.Courses.Form': 'Formularz',
  'menu.Users.Roles': 'Role',
  'menu.Sales': 'Sprzedaż',
  'menu.Users.User': 'Użytkownik',
  'menu.Users.User Form': 'Użytkownicy',
  'menu.Welcome': 'Witaj',
  'menu.Courses.Course Form': 'Formularz kursu',
  'menu.Users.Form': 'Formularz',
  'menu.Courses.H5Ps': 'H5P',
  'menu.Courses.Categories': 'Kategorie',
  'menu.Configuration': 'Konfiguracja',
  'menu.Other activities': 'Inne aktywności',
  'menu.Configuration.Files': 'Pliki',
  'menu.Other activities.Pages': 'Strony',
  'menu.Sales.Payments': 'Płatności',
  'menu.Sales.Orders': 'Zamówienia',
  'menu.Sales.Products': 'Produkty',
  'menu.Courses.SCORMs': 'SCORMy',
  'menu.Analytics': 'Analityka',
  'menu.Analytics.Notifications': 'Powiadomienia',
  'menu.settings': 'Ustawienia',
  'menu.Settings': 'Ustawienia',
  'menu.Configuration.settings': 'Opcje',
  'menu.Analytics.reports': 'Raporty',
  'menu.Courses.Webinars': 'Webinary',
  'menu.Courses.Quiz Reports': 'Raporty z quizów',
  'menu.Courses.Details': 'Szczegóły',
  'menu.Courses.Access Requests': 'Prośby o dostęp',
  'menu.Users.User Groups': 'Grupy',
  'menu.Configuration.Templates': 'Szablony',
  'menu.Configuration.Translations': 'Tłumaczenia',
  'menu.Other activities.Questionnaire': 'Ankiety',
  'menu.Other activities.Consultations': 'Konsultacje',
  'menu.Other activities.Form': 'Formularz',
  'menu.Configuration.Panel Translations': 'Tłumaczenia w panelu',
  'menu.Analytics.Logs': 'Logi',
  'menu.Competency challenges': 'Wyzwania',
  'menu.Competency challenges.List': 'Lista',
  'menu.Competency challenges.Competency challenge': 'Wyzwanie',
  user_settings: 'Ustawienia użytkownika',
  global_settings: 'Ustawienia globalne',
  'user.settings': 'Ustawienia użytkownika',
  'user.settings.overwrite': 'Nadpisz wszystko',
  'menu.Sales.Vouchers': 'Vouchery',
  'menu.Other activities.Consultation Requests': 'Prośby o konsultacje',
  'menu.Other activities.StationaryEvents': 'Wydarzenia stacjonarne',
  'menu.Other activities.Questionnaire Form': 'Ankiety',
  'stationary_event.edit': 'Formularz Wydarzenia stacjonarne',
  stationary_event: 'Wydarzenie stacjonarne',
  'menu.reset': 'reset',
  finished_at: 'Data zakończenia',
  finished_at_tooltip: 'Data zakończenia',
  max_participants: 'Max ilość uczestników',
  max_participants_tooltip: 'Maksymalna ilość uczestników',
  place: 'Miejsce',
  place_tooltip: 'Miejsce',
  started_at: 'Data rozpoczęcia',
  started_at_tooltip: 'Data rozpoczęcia',
  end_at: 'Data zakończenia',
  id: 'id',
  'menu.Users.Permissions': 'Uprawnienia',
  StationaryEvents: 'Wydarzenia stacjonarne',
  settings: 'Settings',
  package: 'Pakiet',
  readonly: 'Tylko do odczytu',
  access: 'Dostęp',
  user_groups: 'Grupy',
  user_group: 'Grupa',
  new_user_group: 'Nowa Grupa',
  user_group_id: 'ID Grupy: ',
  edit_user_group: 'Edycja Grupy',
  add_person_to_group: 'Wybierz osobę z listy aby dodać ją do grupy',
  Active: 'Aktywny',
  active: 'Aktywny',
  answer: 'Odpowiedź',
  answers: 'Odpowiedzi',
  Inactive: 'Nieaktywny',
  inactive: 'Nieaktywny',
  address: 'Adres',
  draft: 'Roboczy',
  published: 'Opublikowany',
  assign: 'Przypisz',
  archived: 'Zarchiwizowany',
  new_template: 'Nowy szablon',
  edit_template: 'Edycja Szablonu',
  preview_pdf: 'Podgląd PDF',
  PDF: 'PDF',
  Push: 'Wyślij',
  SMS: 'SMS',
  vars_set: 'Typ zmiennych',
  vars_set_tooltip: 'Typ zmiennych to np Certyfikaty, email o nowym koncie, itp',
  template: 'Szablon',
  templates: 'Szablony',
  full_name: 'Pełna nazwa',
  slug: 'Slug',
  name: 'Nazwa',
  ID: 'ID',
  newCategory: 'Utwórz nową kategorię',
  editCategory: 'Edytuj kategorię',
  parent_category: 'Nadrzędna kategoria',
  is_active: 'Aktywny?',
  is_highlighted: 'Wyróżniony?',
  parent_id: 'Nadrzędna kategoria',
  parent_id_tooltip: 'Nadrzędna kategoria, do której będzie przypisana nowa kategoria',
  parent_id_group: 'Nadrzędna grupa',
  parent_id_group_tooltip:
    'Wynik wyszukiwania zwróci wszystkie kategorie, które są podpięte do nadrzędnej kategorii',
  registerable: 'Pokaż w rejestracji',
  import_file: 'Importuj plik',
  edit: 'Edytuj',
  delete: 'Usuń',
  deleteQuestion: 'Czy na pewno chcesz usunąć ten rekord?',
  payments: 'Płatności',
  order: 'Zamówienie',
  orders: 'Zamówienia',
  amount: 'Ilość',
  status: 'Status',
  users: 'Użytkownicy',
  user: 'Użytkownik',
  first_name: 'Imię',
  last_name: 'Nazwisko',
  email: 'Email',
  email_tooltip: 'Email',
  email_resend: 'Email został wysłany ponownie',
  email_field: 'Proszę podać email',
  event: 'Wydarzenie',
  general: 'Ogólne',
  password: 'Hasło',
  current_password: 'Aktualne hasło',
  new_password: 'Nowe hasło',
  new_confirm_password: 'Potwierdź nowe hasło',
  password_field: 'Proszę podać hasło',
  note: 'Notatka',
  to: 'do',
  notifications: 'Powiadomienia',
  change_password: 'Zmień hasło',
  search: 'Wyszukaj',
  search_tooltip_1: 'Szukanie odbywa się po imieniu, nazwisku oraz emailu',
  search_tooltip_user_groups: 'Wyszukiwanie odbywa się po nazwie',
  roles: 'Role',
  rodo: 'Polityka prywatności',
  regulations: 'Regulamin serwisu',
  permissions: 'Uprawnienia',
  verified: 'Zweryfikowany',
  not_verified: 'Niezweryfikowany',
  is_email_verified: 'Email zweryfikowany?',
  email_verified: 'Email zweryfikowany',
  dateRange: 'Zakres dat',
  created_at: 'Utworzono',
  create_new: 'Utwórz nowy',
  count_h5p: 'Ilość h5p',
  library_id: 'ID biblioteki',
  updated_at: 'Zaktualizowano',
  unsaved_changes: 'Wprowadzone zmiany mogą nie zostać zapisane.',
  currency: 'Waluta',
  subtotal: 'Suma częściowa',
  tax: 'Podatek',
  total: 'Suma',
  items: 'Pozycje',
  author: 'Autor',
  course: 'Kurs',
  select: 'Proszę wybrać',
  select_tags: 'Wybierz tagi',
  select_person: 'Wybierz osobę',
  select_course: 'Wybierz kurs',
  select_groups: 'Wybierz grupy',
  select_group: 'Wybierz grupę',
  select_final_grade: 'Wybierz ocenę końcową',
  select_all: 'Zaznacz wszystkie',
  deselect_all: 'Odznacz wszystkie',
  select_templates: 'Wybierz szablony',
  select_type_topic: 'Wybierz rodzaj tematu żeby kontynuować',
  select_scorm_package: 'Wybierz pakiet SCORM',
  select_certificate_package: 'Wybierz szablon certyfikatu',
  billable: 'Rachunek do',
  payable: 'Płatne do',
  newH5P: 'Nowy plik H5P',
  H5P_scorm_preview: 'H5P. Podgląd treści SCORM',
  H5P_uploaded: 'Nowy plik H5P dodano prawidłowo',
  H5P_preview_title: 'H5P. Podgląd treści HTML',
  H5P_new_content_title: 'H5P. Nowa treść HTML5',
  H5P_edit_content_title: 'H5p. Edycja',

  H5P_select_content: 'Wybierz treść H5P',
  upload_click_here: 'Kliknij tutaj żeby dodać',
  upload: 'Dodaj plik',
  preview: 'Podgląd',
  export: 'Eksport',
  able_to_preview: 'Możliwość podglądu',
  loading_content: 'Ładowanie treści...',
  preview_content: 'Podgląd treści',
  version: 'Wersja',
  SCOS: 'Udostępniane treści obiektów',
  newScorm: 'Nowy SCORM',
  scorm_uploaded: 'Dodano nowy SCORM',
  yes: 'Tak',
  no: 'Nie',
  cantDelete: 'Nie możesz usunąć tej kategorii, ponieważ jest ona nadrzędna dla innych',
  group: 'Grupa',
  key: 'Klucz',
  type: 'Typ',
  enumerable: 'Przeliczalny',
  public: 'Publiczny',
  value: 'Wartość',
  editSetting: 'Edytuj',
  newSetting: 'Utwórz',
  success: 'Operacja zakończona pomyślnie',
  error: 'Wystąpił błąd',
  loading: 'Ładowanie',
  subtitle: 'Podtytuł',
  hour: 'Godzina',
  language: 'Język',
  level: 'Poziom',
  active_from: 'Aktywny od',
  active_to: 'Aktywny do',
  hours_to_complete: 'Godziny do ukończenia',
  purchasable: 'Do kupienia',
  findable: 'Możliwy do wyszukania',
  target_group: 'Grupa docelowa',
  author_tutor: 'Autor / Nauczyciel',
  short_description: 'Krótki opis',
  summary: 'Podsumowanie',
  summary_tooltip:
    'Dany edytor WYSIWYG zawiera narzędzia do formatowania, zachowując jednocześnie możliwość pisania Markdown z klawiatury oraz wyświetlania zwykłego Markdown.',
  description: 'Opis',
  description_tooltip:
    'Dany edytor WYSIWYG zawiera narzędzia do formatowania, zachowując jednocześnie możliwość pisania Markdown z klawiatury oraz wyświetlania zwykłego Markdown.',
  content: 'Treść',
  contact: 'Kontakt',
  country: 'Kraj',
  slug_tooltip: 'SLUG to część adresu URL pod jakim dostępna będzie strona',
  content_tooltip:
    'Dany edytor WYSIWYG zawiera narzędzia do formatowania, zachowując jednocześnie możliwość pisania Markdown z klawiatury oraz wyświetlania zwykłego Markdown.',
  attributes: 'Atrybuty',
  new_course: 'Nowy kurs',
  new_questionnaire: 'Nowa Ankieta',
  questionnaires: 'Ankiety',
  questionnaire: 'Ankieta',
  'questionnaire.submit': 'Zatwierdź',
  'questionnaire.raports.filter': 'Filtruj po przypisanych modelach',
  questions: 'Pytania',
  question_list: 'Lista pytań',
  question_add: 'Dodaj pytanie',
  question_edit: 'Edytuj pytanie',
  media: 'Media',
  image: 'Zdjęcie',
  import_users: 'Import użytkowników',
  poster: 'Plakat',
  position: 'Pozycja',
  video: 'Wideo',
  audio: 'Dźwięk',
  categories_tags: 'Kategorie i tagi',
  program: 'Program',
  program_tooltip:
    'Dany edytor WYSIWYG zawiera narzędzia do formatowania, zachowując jednocześnie możliwość pisania Markdown z klawiatury oraz wyświetlania zwykłego Markdown',
  scorm: 'SCORM',
  relase_drop: 'Zwolnij, aby upuścić',
  sum_rate: 'Ranking',
  show_hide_on_front: 'Pokaż/Ukryj na froncie',
  visible: 'Widoczny',
  hidden: 'Ukryty',
  question_answer_visibility_changed: 'Zmieniono widoczność odpowiedzi',
  count_answers: 'Ilość odpowiedzi',
  drag_here: 'Przeciągnij tutaj',
  add_new_lesson: 'Dodaj nową lekcję',
  add_lesson: 'Dodaj lekcję',
  lesson: 'Lekcja',
  topic: 'Temat',
  topics: 'Tematy',
  topic_list: 'Lista Tematów',
  topic_types: 'Typy Tematów',
  tutor: 'Trener',
  tutors: 'Trenerzy',
  pages: 'Strony',
  page: 'Strona',
  new_page: 'Nowa strona tekstowa',
  edit_page: 'Edycja strony tekstowej',
  add_new_topic: 'Dodaj nowy temat',
  no_topics: 'Brak tematów w tej lekcji',
  no_data: 'Brak danych',
  create: 'Utwórz',
  save: 'Zapisz',
  sort: 'Sortuj',
  sort_element_up: 'Sortuj ten element w górę',
  sort_element_down: 'Sortuj ten element w dół',
  copy_lesson: 'Kopiuj Lekcje',
  copy_topic: 'Kopiuj Temat',
  'h5p.description.header': 'Informacje dot. H5P',
  'h5p.description.first': 'Aby przypisać interaktywny element HTML5 do tematu lekcji, musisz',
  'h5p.description.second': ' najpierw go utworzyć ',
  'h5p.description.third': 'lub użyć któregoś',
  'h5p.description.fourth': ' z listy',
  'h5p.description.fifth': 'jeśli już jest stworzony. Naciśnij przycisk ',
  'h5p.description.sixth': 'aby zobaczyć jak wygląda treść i zobaczyć listę zdarzeń `XAPI`.',
  my_profile: 'Mój profil',
  logout: 'Wyloguj',
  true: 'Prawda',
  false: 'Fałsz',
  bio: 'Biogram',
  bio_tooltip:
    'Dany edytor WYSIWYG zawiera narzędzia do formatowania, zachowując jednocześnie możliwość pisania Markdown z klawiatury oraz wyświetlania zwykłego Markdown.',
  avatar: 'Awatar',
  avatar_placeholder: 'Dodaj awatar żeby zobaczyć podgląd',
  wysiwyg_placeholder:
    'Zacznij wpisywać tekst, aby zobaczyć funkcje edytora. Zacznij od „/”, aby otworzyć opcje formatowania lub kliknij przycisk „+”',
  edit_user: 'Edycja użytkownika',
  new_user: 'Nowy użytkownik',
  send: 'Wyślij',
  resend: 'Wyślij jeszcze raz',
  back: 'Wróć',
  back_home: 'Wróć do strony głównej',
  '403_subtitle': 'Nie masz dostępu do tej strony.',
  '404_subtitle': 'Przepraszamy, ta strona nie istnieje.',
  user_id: 'ID Użytkownika: ',
  order_id: 'ID Zamówienia: ',
  webinar_id: 'ID Webinaru: ',
  stationary_event_id: 'ID Wydarzenia stacjonarnego: ',
  course_id: 'ID Kursu: ',
  consultation_id: 'ID Konsultacji: ',
  gift_quiz_id: 'ID GIFT Quizu: ',
  new_payment: 'Nowe',
  paid: 'Opłacone',
  cancelled: 'Anulowane',
  processing: 'Przetwarzane',
  cancel: 'Anuluj',
  ok: 'OK',
  failed: 'nie powiodło się',
  clear_selection: 'wyczyść',
  form: 'Formularz',
  none: 'Brak',
  too_many_chars: 'Za dużo znaków',
  CoursesMoneySpentMetric: 'Wskaźnik wydatków na kursy',
  CoursesPopularityMetric: 'Wskaźnik popularności kursów',
  CoursesSecondsSpentMetric: 'Wskaźnik spędzonego czasu na kursach',
  TutorsPopularityMetric: 'Wskaźnik popularności nauczycieli',
  AverageTimePerTopic: 'Średni czas spędzony na temacie',
  MoneyEarned: 'Zarobione pieniądze',
  CertificateTemplates: 'Szablony certyfikatów',
  AverageTime: 'Całkowity spędzony czas na tematach',
  People: 'Ludzie',
  PeopleBought: 'Ilość osób, która kupiła kurs',
  PeopleFinished: 'Ilość osób, która skończyła kurs',
  PeopleStarted: 'Ilość osób, która zaczęła kurs',
  reuse_existing: 'Użyj istniejącego',
  open_new_content_editor: 'Otwórz nowy edytor treści',
  example_json: 'Przykładowy JSON',
  can_skip: 'Możliwość pominięcia',
  statistics: 'Statystyki',
  certificates: 'Certyfikaty',
  query: 'Szukaj',
  reset: 'Wyczyść',
  reset_password: 'Resetuj hasło',
  search_file: 'Wyszukaj plik',
  product: 'Produkt',
  'product.edit': 'Edycja Produktu',
  'menu.Other activities.Products': 'Produkty',
  json: 'JSON Metadata',
  additional: 'Dodatkowe',
  json_tooltip:
    'To pole słuzy programistom aby dodać dodatkowe dane do Tematu, do obsługi przy wyświetlaniu kursu',
  open_editor: 'Otwórz edytor',
  json_editor: 'Otwórz edytor JSON',
  introduction: 'Wprowadzenie',
  introduction_tooltip:
    'Dany edytor WYSIWYG zawiera narzędzia do formatowania, zachowując jednocześnie możliwość pisania Markdown z klawiatury oraz wyświetlania zwykłego Markdown.',
  'webinar.edit': 'Edycja Webinaru',
  role: 'Rola',
  'course.validate_edit.header': 'Ten kurs już trwa! Czy na pewno chcesz go edytować?',
  'course.validate_edit.content':
    'Nie powinno sie edytować tego kursu bo kurs już trwa. Klikająć ok edycja bedzie możliwa ale robisz to na własną odpowiedzialność',
  limit_total: 'Limit ogólny',
  limit_total_tooltip: 'Maksymalna liczba produktów, które będzie można zakupić',
  limit_per_user: 'Limit per uzytkownik',
  limit_per_user_tooltip:
    'Limit kupna na osobę. Przykładowo wartość 1 pozwoli użytkownikowi na zakup jednego produktu',
  extra_fees: 'Extra opłaty {currency}',
  extra_fees_tooltip: 'Dodatkowe opłaty, które będą pobrane przy zakupie produktu',
  price_old: 'Stara cena {currency}',
  price_old_tooltip: 'Stara cena, która będzie wyświetlana jako przekreślona obok aktualnej ceny',
  tax_value: 'Podatek wartość {currency}',
  tax_value_tooltip: 'Wartość podatku, obliczana automatycznie',
  price_brutto: 'Cena brutto {currency}',
  price_brutto_tooltip: 'Cena brutto produktu, obliczana automatycznie',
  tax_rate: 'Podatek stawka',
  tax_rate_tooltip: 'Stawka podatku określona w procentach',
  price: 'Cena {currency}',
  price_in_cents: 'Cena (w groszach)',
  price_tooltip: 'Cena produktu',
  teaser_url: 'Teaser URL',
  teaser_url_tooltip: 'URL do zwiastunu',
  select_product: 'Wybierz produkt',
  productables: 'Obiekt przypisany do produktu',
  productables_tooltip: 'Obiekt przypisany do produktu',
  bundle: 'Pakiet',
  single: 'Pojedyńczy',
  StationaryEvent: 'Wydarzenie stacjonarne',
  Course: 'Kurs',
  Consultation: 'Konsultacja',
  default: 'Domyślna Wartość',
  text: 'text',
  varchar: 'varchar',
  number: 'number',
  boolean: 'boolean',
  editModelField: 'Edycja Dodatkowych Attrybutów',
  ModelFields: 'Dodatkowe Atrybuty',
  list: 'Lista',
  newModelField: 'Nowy Dodatkowy Attrybut',
  notPowerOfTwo: 'To nie jest potęga 2',
  rules: 'Zasady walidacji',
  visibility: 'Widoczność pola',
  name_tooltip: 'Nazwa',
  type_tooltip:
    'Określ typ pola: pole jednokrotnego wyboru, pole numeryczne, pole varchar, pole tekstowe lub format JSON',
  rules_tooltip: 'a json list of string rules used on create/update model',
  extra: 'Wartości dodatkowe',
  extra_tooltip:
    'Dodatkowe wartości w formacie JSON. Przykładowo, jeśli chcemy stworzyć tłumaczenia pól, musimy w edytorze wprowadzić wartości: [{"pl": "Polskie tłumaczenie"},{"en": "English translation"}]',
  default_tooltip:
    'Wartość domyślna dla wybranego pola, która będzie wyświetlana jeśli wartość nie została jeszcze zapisana w bazie danych',
  visibility_tooltip:
    'Widoczność pola dla API. Wartość 1 - publicznie widoczne, 2 - widoczne dla zalogowanych, 4 - widoczne dla administratora.',
  'available-validation-rules': 'dostępne metody walidacji',
  'available-validation-rules-tooltip':
    'Walidacja wybranego pola. Przykładowo, jeśli chcemy, aby pole było wymagane to w edytorze JSON musimy podać wartość: ["required"]',
  Products: 'Produkty',
  free: 'darmowy',
  free_capi: 'Darmowy',
  prices: 'Ceny',
  additional_fields: 'Dodatkowe pola',
  purchasable_tooltip: 'Mozliwość zakupu, albo tylko ręczne przypisanie',
  user_to_add: 'Przypisz użytkownika do produktu',
  user_submission: 'Zapisanie użytkownika bez konta',
  email_to_add: 'Dodaj użytkownika po adresie email',
  Webinar: 'Webinar',
  productable: 'Encja',
  all: 'Wszystkie',
  categories_and_tags: 'Kategorie i Tagi',
  course_edit_warning_message:
    'Ten rekord już trwa, jeśli chcesz edytować kliknij zatwierdź. Edytujesz na własną odpowiedzialność',
  user_logs: 'Logi użytkownika',
  http_method: 'Metoda HTTP',
  path: 'Ścieżka',
  generated_pdfs: 'Wygenerowane PDFy',
  download_all: 'Pobierz wszystkie',
  download_pdf: 'Pobierz PDF',
  select_content: 'Wybierz kontent',
  exclude_promotions: 'Nie łącz z innymi promocjami',

  generate: 'Generuj',
  generate_pdf: 'Generuj PDF(y)',
  generate_pdf_tooltip: 'Generuj PDF(y)',
  generate_pdf_users_tooltip:
    'Klinij aby wygenerować PDFy dla tego szablonu dla wszystkich uzytkowników z listy',
  generate_pdf_products_tooltip:
    'Klinij aby wygenerować PDFy dla tego szablonu dla wszystkich uzytkowników którzy zakupili wybrany produkt',
  generate_pdf_for_products_tooltip:
    'Klinij aby wygenerować PDFy dla wybranego szablonu dla wszystkich uzytkowników którzy zakupili ten produkt',
  select_template: 'Select template',
  product_categories_and_tags: 'Kategorie i Tagi produktu',
  cart_media: 'Media do koszyka',
  agenda: 'Agenda',
  users_attached: 'Dołączenie użytkownika',
  generate_token: 'Generuj token dla youtube',
  browse: 'Przeglądaj pliki',
  related_products: 'Powiązane produkty',
  selected_products: 'Wybrane obiekty',
  quantity: 'Ilość',
  gt_last_login_day: '>= Logowanie z ostatnich n dni',
  lt_last_login_day: '<= Logowanie z ostatnich n dni',
  created_before: 'Utworzony przed',
  created_after: 'Utworzony po',
  redirect: 'przekierowanie',
  logotype: 'Logotyp',
  published_unactivated: 'Opublikowany nieaktywny',
  active_from_extra: 'Wybierz datę rozpoczęcia sprzedaży',
  active_to_extra: 'Wybierz datę zakończenia sprzedaży',
  duration_extra: 'Określ czas trwania sprzedaży w minutach',
  course_success_modal_title: 'Gratulacje!',
  course_success_modal_content: 'Utworzyłeś kurs. Teraz możesz tworzyć lekcje dla swojego kursu.',
  course_success_modal_button_ok: 'Stwórz moduły',
  course_success_modal_button_cancel: 'Idź do strony kursu',
  program_form_empty_list_message:
    'Nie masz wybranej żadnej treści. Stwórz pierwszą lekcję żeby zacząć.',
  program_form_not_selected_list_message: 'Wybierz lekcję albo moduł do edytowania.',
  clone: 'Klonuj',
  drag_topic_tooltip: 'Przeciągnij aby zmienić kolejność materiału',
  invalidDate: 'Błędna data',
  badPassword: 'min. 8 znaków, 1 duża litera, 1 znak specjalny (!@#$%^&*))',
  product_widget_title: 'Atrybuty produktu',
  product_widget_tooltip: 'Wszystkie poniższe atrybuty są związane tylko z produktem',
  course_guide_title: 'Nie wiesz jak tworzyć kurs?',
  course_guide_text: 'Sprawdź nasz poradnik i dowiedz się jak tworzyć kurs.',
  global_settings_alert_title: 'Dodaj ustawienia globalne',
  global_settings_alert_description:
    'Globalne ustawienia są udostępniane dla całego LMS. Możesz dodać globalne ustawienia klikając przycisk poniżej.',
  go_to_platform: 'Przejdź do Twojej platformy',
  manage_course: 'Zarządzaj kursem',
  tutorial: 'Poradnik',
  tutorial_card_left_title: 'Jesteś gotowy aby współdzielić swoje wiedzę.',
  tutorial_card_left_description:
    'Użyj naszych wskazówek i poradników aby zaplanować, stworzyć i opublikować wysokiej jakości kurs.',
  tutorial_card_right_title: 'Nie wiesz jak tworzyć kurs?',
  tutorial_card_right_description: 'Sprawdź nasz poradnik i dowiedz się jak stworzyć kurs.',
  learn_more: 'Dowiedz się więcej',
  your_courses: 'Twoje kursy',
  unverified: 'Niezweryfikowany',
  download: 'Pobierz',
  newTranslation: 'Nowe tłumaczenie',
  documentation: 'Dokumentacja',
  ratings: 'Oceny',
  ratings_description: 'Zmierz oceny dla Twoich kursów',
  hall_of_fame: 'Galeria sław',
  current_user_status: 'Obecny status użytkownika',
  public_course: 'Publiczny',
  no_pricing: 'Brak ceny',
  public_label: 'Publiczny (dostępny bezpłatnie)',
  public_tooltip: ' Kurs jest dostępny publicznie bez potrzeby posiadania konta ',
  changesToBeApproved: 'Zmiany do zatwierdzenia',
  orderHasBeenChanged: 'Kolejność została zmieniona',
  or: 'lub',
  no_base_url: "Wartość pola 'return_url' nie została ustawiona. Przejdź pod: ",
  max_attempts: 'Maksymalna liczba prób',
  max_execution_time: 'Maksymalny czas trwania',
  max_execution_time_tooltip: 'Maksymalny czas trwania egzaminu wyrażony w minutach.',
  min_pass_score: 'Minimalny pozytywny wynik',
  min_pass_score_tooltip: 'Minimalny wynik który musi uzyskać student aby zdać (wyrażony w %).',
  status_consultation_tooltip: 'Status',
  tutor_consultation_tooltip: 'Trener',
  proposed_terms_tooltip: 'Proponowane terminy',
  quiz_reports: 'Raporty z quizów',
  details: 'Szczegóły',
  uncompleted: 'Nieukończony',
  student: 'Uczeń',
  result_score: 'Wynik całościowy',
  max_score: 'Wynik maksymalny',
  gift_quiz: 'GIFT Quiz',
  quiz_report_details: 'Szczegóły raportu z quizu',
  'gift_type.multiple_choice': 'Wielokrotnego wyboru',
  'gift_type.multiple_choice_with_multiple_right_answers':
    'Wielokrotnego wyboru z wieloma właściwymi odpowiedziami',
  'gift_type.true_false': 'Prawda & Fałsz',
  'gift_type.matching': 'Dopasowywanie',
  'gift_type.numerical_question': 'Liczbowe',
  'gift_type.essay': 'Praca pisemna',
  'gift_type.description': 'Opis',
  'gift_type.short_answers': 'Krótkie odpowiedzi',
  possible_score: 'Możliwy wynik',
  possible_answers: 'Możliwe odpowiedzi',
  student_score: 'Wynik ucznia',
  student_answer: 'Odpowiedź ucznia',
  true_answer: 'Prawda',
  false_answer: 'Fałsz',
  possible_true_false: 'Prawda, Fałsz',
  matching_questions: 'Pytania do dopasowania',
  matching_answers: 'Odpowiedzi do dopasowania',
  student_didnt_answer: 'Uczeń nie odpowiedział na to pytanie',
  'User Not Found': 'Użytkownik nie został znaleziony',
  notify_users: 'Użytkownicy do powiadomienia',
  notify_users_tooltip:
    'Wskaż którzy użytkownicy powinni być powiadomieni o tym, że uczeń wgrał projekt',
  root: 'Węzeł początkowy',
  'Product Not Found': 'Produkt nie został znaleziony',
  'Consultation Not Found': 'Konsultacja nie została znaleziona',
  'Order Not Found': 'Zamówienie nie zostało znalezione',
  field_required: 'Pole jest wymagane',
  number_between: 'Pole musi mieć wartość pomiędzy {min} a {max}',
  must_be_a_number: 'Wartość pola musi być liczbą',
  add_title_here: 'Dodaj tytuł',
  user_projects: 'Zadania użytkowników',
  project_solutions: 'Rozwiązania zadań użytkowników',
  'Course cloning started. This may take a while.': 'Trwa klonowanie kursu. To może zająć chwilę',
  'Course deleted successfully': 'Kurs został usunięty',
  'Course imported successfully': 'Kurs został zaimportowany',
  'Course updated successfully': 'Kurs został zaktualizowany',
  'Export created': 'Poprawnie wyeksportowano plik',
  'QuestionType.rate': 'Pytanie',
  'QuestionType.text': 'Tekst',
  'QuestionType.review': 'Recenzja (ocena + komentarz)',
  'model.boolean': 'Pole jednokrotnego wyboru',
  'model.number': 'Pole numeryczne',
  'model.varchar': 'Pole tekstowe (varchar)',
  'model.text': 'Pole tekstowe',
  'model.json': 'JSON',
  'course.finished': 'Ukończenie kursu',
  'course.attempt_number': 'Numer podejścia',
  'course.another_attempts': 'Ponowne podejścia',
  'course.interactions_time_in_days': 'Czas spędzony w poszczególnych dniach',
  'TeacherSubjects.Exams.exam_date': 'Data egzaminu',
  'TeacherSubjects.Exams.grade_weight': 'Waga',
  'SemesterType.winter': 'Zima {year}',
  'SemesterType.summer': 'Lato {year}',
  'ExamGradeType.manual': 'Ręcznie',
  'ExamGradeType.teams_forms': 'MS Teams',
  'ExamGradeType.teams_lecture': 'Test Portal',
  'ExamGradeType.test_portal': 'MS Teams Forms',
  'TeacherSubjects.Exams.results': 'Wyniki',
  'TeacherSubjects.Exams.manualConvert': 'Wybierz grupy którym chcesz przypisać oceny',
  // Long story short - client mixed types of files - so it's working like that:
  'TeacherSubjects.Exams.teams_formsConvert': 'Wgraj plik XLSX (MS Teams)',
  'TeacherSubjects.Exams.teams_lectureConvert': 'Wgraj plik (Test Portal)',
  'TeacherSubjects.Exams.test_portalConvert': 'Wgraj plik XLSX (MS Teams Forms)',
  degree: 'Stopień',
  grade: 'Ocena',
  max_percent: 'Max procent (%)',
  min_percent: 'Min procent (%)',
  min_points: 'Minimalna liczba punktów',
  'TeacherSubjects.FinalGrades.Student': 'Student: {first_name} {last_name}',
  'TeacherSubjects.FinalGrades.StudentPartialGrades': 'Oceny cząstkowe',
  'TeacherSubjects.FinalGrades.Attendance': 'Obecność',
  'TeacherSubjects.FinalGrades.Attendances': 'Obecności',
  'TeacherSubjects.FinalGrades.FinalGrades': 'Oceny końcowe',
  'TeacherSubjects.FinalGrades.GradesScale': 'Skala ocen dla przedmiotu',
  'TeacherSubjects.FinalGrades.CoursesProgress': 'Postęp w kursach',
  'TeacherSubjects.FinalGrades.GradeTerm': 'Termin zaliczenia',
  'TeacherSubjects.FinalGrades.FinalGrade': 'Ocena końcowa',
  'TeacherSubjects.FinalGrades.ProposedGrade': 'Proponowana ocena: {grade}',
  'TeacherSubjects.FinalGrades.IssueAssessment': 'Wystaw ocenę końcową',
  date: 'Data',
  issued_at: 'Wystawiono',
  icon: 'Ikona',
  approved: 'Zatwierdzony',
  pending: 'W trakcie',
  extra_data: 'Dodatkowe dane',
  show: 'Pokaż',
  phone_number: 'Numer kontaktowy',
  contact_details: 'Szczegóły kontaktu',
  assigned: 'Przypisany',
  completed_at: 'Ukończone',
  Finished: 'Zakończone:',
  Started: 'Rozpoczęte:',
  NotStarted: 'Nie rozpoczęte:',
  ShowMinutes: 'Pokaż minuty spędzone nad tematem:',
  sent: 'Wysłane',
  accepted: 'Zaakceptowane',
  rejected: 'Odrzucone',
  'confirmModal.title': 'Potwierdź zmiany',
  'confirmModal.content': 'Jeśli chcesz przejść do kolejnej zakładki zapisz aktualne zmiany',
  read_all: 'Przeczytaj wszystko',
  new_lessons_cant_be_moved: 'Nie można przenieść nowej lekcji',
  topics_cant_be_nested: 'Nie można zagnieżdżać tematów',
  topic_cant_be_in_root: 'Temat musi być zagnieżdżony w lekcji',
  lessons_and_topics_cant_be_mixed: 'Lekcje i tematy nie mogą być układane naprzemiennie',
  max_lesson_nesting: 'Maksymalny poziom na którym mogą być lekcje to {max}',
  min_topic_nesting: 'Minimalny poziom na którym mogą być tematy to {min}',
  stacking_order: 'Kolejność',
  stacking_order_tooltip: 'Kolejność lekcji w kontekście lekcji lub kursu',
  exportData: 'Eksportuj dane',
  created_by: 'Utworzono przez',
  exported_at: 'Eksportowano',
  exported_by: 'Eksportowano przez',
  examTitleWithWeight: '{title} (waga: {weight}%)',
  classRegisterTitleWithGroupName: 'Dziennik grupy {groupName}',
  gradesScalesMissing: 'Brak skali ocen dla wybranego dydaktyka',
  proposed_grade: 'Proponowana ocena',
  groupDataMissing: 'Brak danych dla grupy {group_name}',
  file_downloaded: 'Pobrano plik',
  file_downloaded_error: 'Nie udało się pobrać pliku',
  present: 'Obecny',
  not_exercising: 'Nie ćwiczący',
  excused_absence: 'Nieobecność usprawiedliwiona',
  pressSaveToCreateQuestions: 'Stwórz ten temat przed dodaniem pytań',
  addNewQuestion: 'Dodaj nowe pytanie',
  selectNewQuestionType: 'Wybierz typ nowego pytania',
  question: 'Pytanie',
  actions: 'Akcje',
  'Questions.delete': 'Usuń pytanie',
  'Questions.edit': 'Edytuj pytanie',
  'Questions.score': 'Wynik',
  'Questions.create': 'Utwórz pytanie',
  'Questions.addMatchingPair': 'Dodaj parę',
  'Questions.add': 'Dodaj pytanie',
  'Questions.addAnswer': 'Dodaj odpowiedź',
  'Questions.deleteAnswer': 'Usuń odpowiedź',
  'Questions.onlyOneAnswerIsCorrect': 'Tylko jedna odpowiedź może być poprawna',
  'Questions.answer': 'Odpowiedź {ordinalNumber}',
  'Questions.correctAnswer': 'Poprawna odpowiedź?',
  'Questions.pair': 'Para {ordinalNumber}',
  'Questions.deletePair': 'Usuń parę',
  'Questions.errorMargin': 'Margines błędu',
  'Questions.answerFeedback': 'Informacja zwrotna',
  weight: 'Waga',
  add_new_component: 'Dodaj nowy komponent',
  category: 'Kategoria',
  categoryId: 'ID Kategorii: ',
  'CompetencyChallenges.main': 'Dane',
  'CompetencyChallenges.scales': 'Skale',
  'CompetencyChallenges.diagnostic-test': 'Test diagnostyczny',
  'CompetencyChallenges.base': 'Podstawowe',
  'CompetencyChallenges.additional': 'Dodatkowe',
  'CompetencyChallenges.new_challenge': 'Nowe wyzwanie',
  'Competency challenge updated successfully.': 'Zaktualizowano wyzwanie',
  'Competency challenge created successfully.': 'Stworzono wyzwanie',
  'CompetencyChallenges.addNewRow': 'Dodaj nowy wiersz',
  'CompetencyChallenges.onlyOneRowIsEditable': 'Jednocześnie można edytować tylko jeden wiersz',
  'CompetencyChallenges.types.simple': 'Poziomujący',
  'CompetencyChallenges.types.complex': 'Ogólny',
  'CompetencyChallenges.highlighted': 'Wyróżnione wyzwanie na stronie głównej',
  highlighted: 'Wyróżniony',
  notHighlighted: 'Niewyróżniony',
  'TopicStatistics.giftQuiz.topic': 'Temat GIFTQuiz',
  'TopicStatistics.giftQuiz.title': 'Statystyki tematu typu GIFTQuiz',
  'TopicStatistics.giftQuiz.attempt': 'Podejście',
  'TopicStatistics.giftQuiz.question': 'Pytanie {i}',
  'TopicStatistics.giftQuiz.attemptDate': 'Data podejścia',
  'TopicStatistics.giftQuiz.attemptDuration': 'Czas trwania podejścia',
  'TopicStatistics.giftQuiz.result': 'Wynik',
  'ConsultationsAccess.meetingType': 'Typ spotkania',
  'ConsultationsAccess.meetingType.custom': 'Niestandardowy',
  'ConsultationsAccess.meetingType.jitsi': 'Jitsi',
  'ConsultationsAccess.meetingType.pencil-spaces': 'Pencil spaces',
  'ConsultationsAccess.meetingLink': 'Link do spotkania',
  'Settings.package': 'Pakiet {package}',
  consultation_term_date: 'Termin konsultacji',
  meeting_link: 'Link do spotkania',
  logs: 'Logi',
  user_info: 'Informacje o użytkowniku',
  importQuestions: 'Importuj pytania',
  exportQuestions: 'Eksportuj pytania',
  exportQuestionsByCategory: 'Eksportuj pytania według kategorii',
  'ExportQuestions.questionTitle': `Pytanie o id: {id}`,
  RichText: 'RichText',
  OEmbed: 'OEmbed',
  Audio: 'Audio',
  Video: 'Video',
  Image: 'Image',
  SCORM: 'ScormSco',
  Project: 'Project',
  GiftQuiz: 'GiftQuiz',
  Unknown: 'Nieznany',
  edit_score: 'Edytuj wynik',
  see_file_example_image: 'Zobacz zdjęcie przykładowego pliku',
};
